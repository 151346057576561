// COLORS
$primary_color: #01448a;
$primary_dark_color: #073a68;

$bg_gradient_light_color: #1c75bc;
$bg_gradient_dark_color: #2b3990;

$secondary_color: #fbb603;

$light_text_color: #fff;
$dark_text_color: #58595b;
$dark_text_color_1: #939598;
$dark_text_color_2: #b3b4b7;
$orange_color: #e2a552;
$orange_color_bold: #df9d42;
$orange_color_bold_2: #e67817;

$orange_color_line: #fbbe20;

$grey_color: #f1f2f2;
$dark_grey_color: #d1d3d4;
$dark_grey_color_2: #bcbec0;

// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;