@import '../abstracts/variables';

@font-face {
  font-family: 'Averta';
  font-weight: bold;
  src: url('../../assets/fonts/Averta-Bold.otf') format('opentype')
}

@font-face {
  font-family: 'Averta';
  font-weight: normal;
  src: url('../../assets/fonts/Averta.otf') format('opentype')
}

@font-face {
  font-family: 'Myriad';
  font-weight: bold;
  src: url('../../assets/fonts/MYRIADPRO-BOLD.woff') format('woff')
}

@font-face {
  font-family: 'Myriad';
  font-weight: normal;
  src: url('../../assets/fonts/MYRIADPRO-REGULAR.woff') format('woff')
}

@font-face {
  font-family: 'Myriad';
  font-weight: 100;
  src: url('../../assets/fonts/MyriadPro-Light.woff') format('woff')
}



b, h1, h2, h3, h4, h5 {
  font-weight: bold;
}

.text-light {
  color: $light_text_color;
}

.text-red {
  color: red;
}

.text-dark {
  color: $dark_text_color
}

.text-blue {
  color: $primary_color;
}