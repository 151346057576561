@import "../../styles/abstracts/variables";

ul.ant-menu {
  & > .ant-menu-item {
    padding-left: 45px !important;
    background: $grey_color;
    color: $dark_text_color_1;
    margin-bottom: 0 !important;
    font-size: 1.4rem;

    &> a {
      color: $dark_text_color_1;
    }
  }
  & > .ant-menu-item-selected {
    background: $secondary_color !important;
    color: white;

    & > a {
      color: white;
    }
    &::after {
      border-right: 0;
    }
  }
  & .ant-menu-submenu {
    & .ant-menu-submenu-title {
      padding-left: 45px !important;
      background: $grey_color;
      color: $dark_text_color_1;
      margin-bottom: 0;
      font-size: 1.4rem;
    }
    & .ant-menu-sub {
      background: $grey_color !important;
      & .ant-menu-item {
        background: white;
      }
    }
    & .ant-menu-submenu-arrow {
      &::before {
        width: 10px;
        height: 2.5px;
        background: white;
        transform: rotate(-35deg) translateX(4px)
      }
      &::after {
        width: 10px;
        height: 2.5px;
        background: white;
        transform: rotate(35deg) translateX(-4px);
      }
    }
  }
  & .ant-menu-submenu-open {
    & .ant-menu-submenu-arrow {
      &::before {
        transform: rotate(35deg) translateX(4px) !important;
      }
      &::after {
        transform: rotate(-35deg) translateX(-4px) !important;
      }
    }
  }
}





